import React, { Component } from "react";
import Menu from "./Navigation/Menu";
import MobileMenu from "./Navigation/MobileMenu";
import Home from "./Screens/Home";
import About from "./Screens/About";
import Portfolio from "./Screens/Portfolio";
import Contact from "./Screens/Contact";
// import Blog from "./Screens/Blog";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./Components/Icons";
// import Blogsignle from "./Screens/Blogsingle";
// import cssNedir from "./img/css-nedir.jpg";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      // signleBlog: [
      //   {
      //     id:0,
      //     adress:"cssnedir",
      //     name:"Css2 Nedir?",
      //     desc:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.`,
      //     src: cssNedir,
      //   },
      //   {
      //     id:1,
      //     adress:"htmlnedir",
      //     name:"html Nedir?",
      //     desc:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.`,
      //     src: cssNedir,
      //   },
      //   {
      //     id:2,
      //     adress:"asd",
      //     name:"asd?",
      //     desc:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.
      //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
      //     arcu tortor, dapibus sit amet ex eget, hendrerit venenatis
      //     eros. Nullam.`,
      //     src: cssNedir,
      //   }
      // ],
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 998 });
  }

  render() {
    const isDesktop = this.state.isDesktop;
    // let _address = [];
    // for (let i = 0; i < this.state.signleBlog.length; i++) {
    //   _address.push(
    //     <Route key ={this.state.signleBlog[i].id} path={"/"+this.state.signleBlog[i].id+"_"+this.state.signleBlog[i].adress}>
    //       <Blogsignle signleBlog = {this.state.signleBlog} />
    //     </Route>
    //   )
    // }

    return (
      <div className="containerPage" style={{ overflowX: "hidden" }}>
        <Router>
          {isDesktop ? <Menu /> : <MobileMenu />}
          <AnimatePresence>
            <Switch>
              {/* <Route path="/blog">
                <Blog signleBlog = {this.state.signleBlog} />
              </Route>
              {_address} */}
              <Route path="/about">
                <About />
              </Route>
              <Route path="/portfolio">
                <Portfolio />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </AnimatePresence>
        </Router>
      </div>
    );
  }
}
